import React, { useCallback, useEffect, useState } from "react";
// Mui Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Menu from "@mui/material/Menu";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// images
import Search from "../../../assets/images/templates/template-search-2.svg";
import Edit from "../../../assets/images/templates/template-edit.svg";
import Delete from "../../../assets/images/templates/template-delete.svg";
import Copy from "../../../assets/images/templates/template-copy.svg";
import NoContact from "../../../assets/images/contacts/no-contact.svg";

// styles
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../utils/date-format";
import {
  deleteTemplate,
  getAllTemplates,
  getViewProof,
} from "../../../redux/actions/template-builder";
import { failure, success } from "../../../redux/actions/snackbar-actions";
import DeleteTemplateModal from "../DeleteModal";
import DuplicateTemplateModal from "../DuplicateModal";
import { useNavigate } from "react-router-dom";
import { MESSAGES } from "../../../utils/message";
// Custom Loader
import Loader from "../../General/Loader";
import { stringLength } from "../../../utils/helperFunctions";
import { displayTooltip } from "../../../hooks/displayTooltip";
// Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";


/**
 * TemplateListing Component
 * Displays a list of templates with various actions.
 * @returns {JSX.Element} The data grid component showing list templates.
 */
const TemplateListing = (props) => {
  const {searchApplied} = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShowDeleteModel, setIsShowDeleteModel] = useState({
    open: false,
    type: "delete",
  });
  const [selectedRecord, setSelectedRecord] = useState({});
  const search = useSelector((state) => state.templateReducer.search);
  const searchProductIds = useSelector(
    (state) => state.templateReducer.searchProductIds
  );
  const searchCreator = useSelector(
    (state) => state.templateReducer.searchCreator
  );
  const templates = useSelector((state) => state.templateReducer.templates);
  const templatesPagination = useSelector(
    (state) => state.templateReducer.templatesPagination
  );
  const [downloadingProof, setDownloadingProof] = useState({
    status: false,
    rowId: null,
  });

  const getAllTemplatesFromServer = (page, perPage) => {
    dispatch(
      getAllTemplates(page, perPage, search, searchProductIds, searchCreator)
    );
  };
  const handleEditNavigate = useCallback(
    (id, templateType) =>
      navigate(
        templateType === "json"
          ? `/template-builder/${id}`
          : `/template-html/${id}`
      ),
    [navigate]
  );

  const handleRowClick = (params, event) => {
    event.stopPropagation();
    handleEditNavigate(params.row.id, params.row.templateType);
  };

  const handleChangePage = (event, page) => {
    getAllTemplatesFromServer(page, templates.perPage);
  };

  const handleShowDeleteDialog = (record) => {
    setIsShowDeleteModel((prev) => ({ ...prev, open: true, type: "delete" }));
    setSelectedRecord(record);
  };

  const handleDuplicateDialog = (record) => {
    setIsShowDeleteModel((prev) => ({
      ...prev,
      open: true,
      type: "duplicate",
    }));
    setSelectedRecord(record);
  };
  const handleDelete = async () => {
    const response = await deleteTemplate(selectedRecord.id);
    if (response.status === 200) {
      setIsShowDeleteModel((prev) => !prev);
      dispatch(success(response.data.message));
      getAllTemplatesFromServer(templates.currentPage - 1, templates.perPage);
    } else {
      dispatch(failure(response.data.message));
    }
  };

  const createViewProof = async (title, id) => {
    try {
      setDownloadingProof({
        status: true,
        id: id,
      });
      const response = await getViewProof(id);
      const binaryData = atob(response.data.data.base64Pdf);

      if (response.status === 200) {
        // Create a Uint8Array from the binary data
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "application/pdf" });

        // Create an Object URL for the Blob
        const url = URL.createObjectURL(blob);
        downloadPDF(title, url);
        dispatch(success("Download Proof generated successfully"));
      } else {
        dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
      }
    } catch (error) {
      dispatch(error?.message);
    } finally {
      setDownloadingProof({
        status: false,
        id: null,
      });
    }
  };

  const downloadPDF = (title, url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = title + ".pdf";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const isTooltip = displayTooltip();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeRowId, setActiveRowId] = useState(null);

  const useScreen = useScreenWidth();

  const columns = [
    {
      field: "templateName",
      headerName: "Template Name",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.title}` + `${params.row.id}`) ? (
          <Tooltip
            title={
              <div>
                {params.row.title}
                <br />
                {params.row.id}
              </div>
            }
            disableHoverListener={isTooltip}
          >
            <Box className="tempValueWrapper">
              <Typography>{params.row.title}</Typography>
              <Typography>ID: {params.row.id}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="tempValueWrapper">
            <Typography>{params.row.title}</Typography>
            <Typography>ID: {params.row.id}</Typography>
          </Box>
        ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      type: "number",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.product.productType}</Typography>
          {params.row.product.productType === "Postcards" && (
            <Typography>{params.row.product.paperSize}</Typography>
          )}
        </Box>
      ),
    },
    {
      field: "templateType",
      headerName: "Template Type",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>
            {params.row.templateType === "json"
              ? "Template Builder"
              : params.row.templateType.toUpperCase()}
          </Typography>
        </Box>
      ),
    },
    {
      field: "creator",
      headerName: "Created By",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.creator?.fullName}`) ? (
          <Tooltip
            title={params.row.creator?.fullName}
            disableHoverListener={isTooltip}
          >
            <Box className="addressWrapper">
              <Typography>{params.row.creator?.fullName}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{params.row.creator?.fullName}</Typography>
          </Box>
        ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.createdAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Last Updated Date",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.updatedAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1350 ? 1 : 0,
      renderCell: (params) => (
        <>
          {useScreen ? (
            <Box className="actionsWrapper">
              <Tooltip title="Download Proof">
                {downloadingProof.status &&
                downloadingProof.id === params.row.id ? (
                  <CircularProgress
                    sx={{
                      color: "#ED5C2F",
                      width: "20px !important",
                      height: "20px !important",
                    }}
                  />
                ) : (
                  <img
                    src={Search}
                    alt="search"
                    onClick={(event) => {
                      event.stopPropagation();
                      createViewProof(params.row.title, params.row.id);
                    }}
                  />
                )}
              </Tooltip>
              <Tooltip title="Duplicate">
                <img
                  src={Copy}
                  alt="duplicate"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDuplicateDialog(params.row);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <img
                  src={Delete}
                  alt="Delete"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowDeleteDialog(params.row);
                  }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  event.stopPropagation();
                  setActiveRowId(params.row); // Set active row ID when the menu is opened
                  handleClick(event);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color:
                      activeRowId?.id === params.row.id && anchorEl
                        ? "#ed5c2f"
                        : "inherit",
                  }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxWidth: "140px",
                  },
                  className: "tempActionsDropdown",
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    createViewProof(activeRowId?.title, activeRowId?.id);
                    handleClose();
                  }}
                >
                  <img src={Search} alt="search" />
                  Download Proof
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDuplicateDialog(activeRowId);
                    handleClose();
                  }}
                >
                  <img src={Copy} alt="duplicate" />
                  Duplicate
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowDeleteDialog(activeRowId);
                    handleClose();
                  }}
                >
                  <img src={Delete} alt="Delete" />
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth])
  

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="templateListingWrapper"
    >
      {isShowDeleteModel.open && isShowDeleteModel.type === "delete" && (
        <DeleteTemplateModal
          open={isShowDeleteModel.open}
          handleClose={() => setIsShowDeleteModel(false)}
          handleDelete={handleDelete}
        />
      )}
      {isShowDeleteModel.open && isShowDeleteModel.type === "duplicate" && (
        <DuplicateTemplateModal
          open={isShowDeleteModel.open}
          handleClose={setIsShowDeleteModel}
          selectedRecord={selectedRecord}
        />
      )}

      <DataGrid
        rows={templates.rows}
        columns={columns}
        pageSizeOptions={[10, 20, 50, 100]}
        rowCount={templates.count}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="templateTable"
        loading={templatesPagination.loading}
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContact} alt="NoContact" />
              {`${MESSAGES.NO_RESULTS_TEXT} ${!searchApplied ? "filters" : "search"}`}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContact} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={Math.ceil(templates.count / templates.perPage)}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={templates.currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Select
          className={
            templates.perPage >= 100
              ? `pageSelect pageSelectChange`
              : `pageSelect`
          }
          value={templates.perPage}
          IconComponent={() => (
            <KeyboardArrowDownIcon
              sx={{ position: "absolute", right: "5px", zIndex: 1 }}
            />
          )}
        >
          {[20, 50, 100].map((pageSize) => {
            return (
              <MenuItem
                key={pageSize}
                value={pageSize}
                onClick={() => {
                  getAllTemplatesFromServer(0, pageSize);
                }}
              >
                {pageSize}
              </MenuItem>
            );
          })}
        </Select>

        <Typography>
          Showing {templates.count ? templates.from : 0} to{" "}
          {templates.count < templates.to ? templates.count : templates.to} of{" "}
          {templates.count} results
        </Typography>
      </Box>
    </div>
  );
};

export default TemplateListing;
