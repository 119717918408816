import React, { useCallback, useEffect, useState } from "react";

// Hooks
import { useNavigate } from "react-router-dom";

//Utils
import { sortOrderForTemplates } from "../../utils/constants";

import {
  Container,
  Grid,
  Box,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Images
import InactiveFilter from "../../assets/images/contacts/filter.svg";
import Create from "../../assets/images/templates/add-icon.svg";
import TemplateListing from "./TemplateListing/TemplateListing";
import ActiveFilter from "../../assets/images/templates/filter-2.svg";
import Search from "../../assets/images/templates/search.svg";
import FilterModal from "./FilterModal";
import { MESSAGES } from "../../utils/message";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllTemplates,
  getAllProducts,
  getAllTemplates,
  searchAndAdvanceChange,
} from "../../redux/actions/template-builder";
import NoTemplateScreen from "./NoTemplateScreen";
import "./styles.scss";
/**
 * Renders a search input, a filter button, a create button, and a template listing component.
 * Handles navigation to the template builder page and manages the state of the search input.
 *
 * @returns {JSX.Element} The rendered Templates component.
 */
const Templates = () => {
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const templates = useSelector((state) => state.templateReducer.templates);
  const totalTemplatesInDB = useSelector(
    (state) => state.templateReducer.totalRecordsInDB
  );

  const search = useSelector((state) => state.templateReducer.search);
  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );
  const searchProductIds = useSelector(
    (state) => state.templateReducer.searchProductIds
  );
  const searchCreator = useSelector(
    (state) => state.templateReducer.searchCreator
  );
  const products = useSelector((state) => state.templateReducer.products);

  const updatedProducts = products
  .map((product) => {
    if (product.productType === "Postcards") {
      return product.size.map((size) => ({
        id: size.id,
        title: `${product.productType} - ${size.size}`,
      }));
    } else if (product.productType === "Professional Letters") {
      const windowedLabel = product.windowed ? "Windowed" : "Non-Windowed";
      return {
        id: product.id,
        title: `${product.productType} - ${windowedLabel}`,
      };
    } else {
      return {
        id: product.id,
        title: product.productType,
      };
    }
  })
  .flat()
  .sort((a, b) => {
    const indexA = sortOrderForTemplates.indexOf(a.title);
    const indexB = sortOrderForTemplates.indexOf(b.title);
    return indexA - indexB;
  });



  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllTemplates(1, 20));
    setIsMounted(true);
    return () => {
      dispatch(clearAllTemplates());
      dispatch(searchAndAdvanceChange("search", ""));
    };
  }, []);

  // Handle navigation to template builder page
  const handleCreate = useCallback(
    () => navigate("/create-template"),
    [navigate]
  );
  const getAllTemplatesFromServer = () => {
    
    const selectedProductIds = searchProductIds.split(',');
    let selectedIds = []
    if(searchProductIds) {
      selectedIds =
        updatedProducts
          .filter((product) => selectedProductIds.includes(product.title)).map((products) =>  Number(products.id));
    }
    dispatch(
      getAllTemplates(
        1,
        templates.perPage,
        search,
        searchProductIds,
        searchCreator,
        '',
        selectedProductIds ? JSON.stringify(selectedIds) : undefined
      )
    );
  };

  const handleSearch = () => {
    if (search) {
      setSearchApplied(true);
      getAllTemplatesFromServer();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && search) {
      event.preventDefault();
      setSearchApplied(true);
      getAllTemplatesFromServer();
    }
  };

  const clearAdvanceFilter = () => {
    dispatch(searchAndAdvanceChange("searchProductIds", ""));
    dispatch(searchAndAdvanceChange("searchCreator", ""));
    dispatch(getAllTemplates(1, templates.perPage, search));
  };

  const filterSize = [searchProductIds, searchCreator].filter(
    (item) => item
  ).length;

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      getAllTemplatesFromServer();
    }
  }, [search]);

  useEffect(() => {
    if (isMounted) {
      clearAdvanceFilter();
    }
  }, [currentMode]);

  const noTemplatesInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoTemplateScreen />
      </Container>
    );
  };

  if (totalTemplatesInDB < 1 && totalTemplatesInDB !== null) {
    return noTemplatesInDb();
  }

  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        margin: "120px auto 50px",
      }}
    >
      {isOpenAdvanceFilter && (
        <FilterModal
          open={isOpenAdvanceFilter}
          handleClose={() => setIsOpenAdvanceFilter(false)}
          handleSearch={() => {}}
          updatedProducts={updatedProducts}
        />
      )}
      <Grid container>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className="searchBtnWrapper"
        >
          <Box className="searchWrapper">
            {/* Search Input */}
            <Input
              className={`searchInput ${searchApplied ? "searchApplied" : ""}`}
              type="text"
              autoComplete="off"
              value={search}
              name="search"
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                dispatch(
                  searchAndAdvanceChange(
                    e.target.name,
                    e.target.value.trimStart()
                  )
                )
              }
              placeholder={MESSAGES.TEMPLATE.SEARCH_PLACE_HOLDER}
              endAdornment={
                <InputAdornment position="end" className="searchInputAdornment">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    className="searchIcon"
                    onClick={handleSearch}
                  >
                    <img src={Search} alt="search" />
                  </IconButton>
                  {searchApplied && (
                    <HighlightOffIcon
                      onClick={() => {
                        dispatch(searchAndAdvanceChange("search", ""));
                        setSearchApplied(false);
                      }}
                      className="clearSerach"
                    />
                  )}
                </InputAdornment>
              }
            />
            {/* Filter Button */}
            <Box className="filterBtnWrapper">
              <img
                onClick={() => {
                  setIsOpenAdvanceFilter(true);
                }}
                className={
                  filterSize > 0
                    ? "filterImage activeFilter"
                    : "filterImage noFilter"
                }
                src={filterSize > 0 ? ActiveFilter : InactiveFilter}
                alt="Filter"
              />
              {filterSize > 0 && (
                <Typography className="filtersCounter">{filterSize}</Typography>
              )}
            </Box>
            {filterSize > 0 && (
              <Button onClick={clearAdvanceFilter} className="clearFilterBtn">
                Clear Filters
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{
            marginTop: { md: "0px", sm: "20px", xs: "20px" },
            alignItems: "flex-start",
          }}
        >
          {/* Create Button */}
          <Box className="actionsBtnWrapper">
            <Button className="templateCreateBtn" onClick={handleCreate}>
              <img src={Create} alt="actions" />
              <Typography>Create</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Template Listing Component */}
      <TemplateListing searchApplied={searchApplied}/>
    </Container>
  );
};

export default Templates;
